import { useState, MouseEvent } from 'react'
import { ReactComponent as InfoCircleIcon } from '../../../../src/assets/icons/info-circle-icon.svg'
import './styles.scss'
import { Popover } from '@mui/material'

interface Props {
  popoverHint: string
}
const CustomPopover = ({ popoverHint }: Props) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null)

  const handlePopover = (event?: MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event ? event.currentTarget : null)
  }

  const isOpen = !!anchorEl

  return (
    <div className="custom-popover">
      <InfoCircleIcon className="custom-popover__icon" onClick={handlePopover} />
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => handlePopover()}
        anchorOrigin={
          {
            vertical: 'center',
            horizontal: 'right',
          }
        }
        transformOrigin={
          {
            vertical: 'center',
            horizontal: 'left',
          }
        }
        className="ml-8"
      >
        <p className="custom-popover__text">{popoverHint}</p>
      </Popover>
    </div>
  )
}

export default CustomPopover

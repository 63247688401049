import axios from 'axios'
import { Course } from '../redux/courses/courseSlice'

function getCourses() {
  return axios.get('api/courses/search')
}

function getCourseDetails(courseId: string) {
  return axios({
    method: 'get',
    url: `api/courses/details/${courseId}`,
  })
}

function findCourseLocale(course: Course, userLocale: string) {
  const defaultLang = 'ru'

  let courseLocale = course.courseLocales.find(locale => locale.lang === userLocale)
  if (!courseLocale) courseLocale = course.courseLocales.find(locale => locale.lang === defaultLang)
  if (!courseLocale) courseLocale = course.courseLocales[0]
  return courseLocale
}

function enrollToCourse(id: string) {
  return axios({
    method: 'post',
    url: `/api/courses/${id}/participation`,
  })
}

export { getCourses, getCourseDetails, findCourseLocale, enrollToCourse }

import './styles.scss'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg'
import { ReactComponent as CourseProgressIcon } from '../../../assets/icons/course-progress-icon.svg'
import { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg'
import { Trans, useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { coursesActions } from '../../../redux/courses/courseSlice'
import { useParams } from 'react-router'
import { findCourseLocale } from '../../../services/Courses'

export default function CourseDetails() {
  const { i18n, t } = useTranslation()
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const course = useAppSelector(state => state.courses.course)
  const courseLocale = findCourseLocale(course, i18n.language)

  function handleCourseEnroll() {
    dispatch(coursesActions.enrollToCourse(id))
  }

  useEffect(() => {
    dispatch(coursesActions.getCourseDetails(id))
  }, [])

  return (
    <div className="course">
      {
        !!course?.courseLocales?.length && (
          <div className="container">
            <div className="course-intro-wrap">
              <div className="course-intro">
                <h2 className="course-intro__title">{courseLocale.title}</h2>
                <div className="course-intro__description">{courseLocale.description}</div>
              </div>
              <img
                className="course-intro-wrap__img"
                src="/assets/images/course-default-img.svg"
                alt="course image"
              />
              <img
                className="course-intro-wrap__img-mobile"
                src="/assets/images/course-default-img-mobile.svg"
                alt="course image"
              />
            </div>

            <div className="course-summary">
              <h3 className="course-summary__title">{t('course-details.summary-title')}</h3>
              <div className="wrap">
                <div className="course-summary__item">
                  <img
                    src=""
                    alt="course summary icon"
                    className="course-summary__icon"
                  />
                  <p className="course-summary__item-name"></p>
                </div>
                <div className="course-summary__item"></div>
              </div>
            </div>
            {
              !course.participation && (
                <button onClick={handleCourseEnroll} className="course__start-btn">
                  {t('course-details.start-btn')}
                </button>
              )
            }
            <div className="course-content">
              <h3 className="course-content__title">{t('course-details.module-overview')}</h3>
              <div className="d-flex course-content-header">
                <img
                  src="/assets/icons/course-content-icon.svg"
                  alt="course icon"
                  className="mr-18"
                />
                <div>
                  <h3 className="course-content-header__name">{courseLocale.title}</h3>
                  <div className="course-content-header__progress">
                    <CourseProgressIcon className="mr-2" />
                    <Trans i18nKey="course-details.progress">{{ count: '3/5' }}</Trans>
                    {/* TODO: replace hardcoded value for count ^ */}
                  </div>
                </div>
              </div>

              <div className="accordion">
                {
                  courseLocale.courseModules.map((courseModule, index) => (
                    <Accordion
                      key={courseModule.courseModuleId}
                      defaultExpanded={index === 0}
                      className="accordion-module"
                    >
                      <AccordionSummary
                        expandIcon={<ChevronDownIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="accordion-module__name"
                      >
                        {courseModule.title}
                      </AccordionSummary>
                      <AccordionDetails className="accordion-lessons">
                        {
                          !!courseModule.courseExercises.length
                          && courseModule.courseExercises.map(exercise => (
                            <div key={exercise.courseExerciseId}>
                              <div className="accordion-lesson d-flex p-10">
                                <img
                                  src=""
                                  alt=""
                                  className="mr-8"
                                />
                                <span className="mr-auto">{exercise.courseExerciseId}</span>
                                <LockIcon className="accordion-lesson__lock-icon" />
                              </div>
                              {
                                index !== courseModule.courseExercises.length - 1 && (
                                  <div className="accordion-lesson-separator"></div>
                                )
                              }
                            </div>
                          ))
                        }
                      </AccordionDetails>
                    </Accordion>
                  ))
                }
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

import './style.scss'
import { Link } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Course } from '../../../../redux/courses/courseSlice'
import { ReactComponent as SaveCourseIcon } from '../../../../assets/icons/course-card-icon-default.svg'
import { findCourseLocale } from '../../../../services/Courses'

export default function CourseCard({ course }: { course: Course }) {
  const { i18n } = useTranslation()
  const courseLocale = findCourseLocale(course, i18n.language)

  return (
    <div className="course-card">
      <button
        className={`course-card__btn ${course.isSaved && 'course-card__btn_saved'}`}
        onClick={() => {}}
      >
        <SaveCourseIcon />
      </button>
      <img
        src={course.image || '/assets/images/course-card-img.svg'}
        className="course-card__img"
        alt="Course image"
      >
      </img>
      <Link
        to={`/course/${course.courseId}`}
        className="course-card__title"
      >
        {courseLocale?.title}
      </Link>
      <div>{courseLocale?.description}</div>
    </div>
  )
}

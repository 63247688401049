import { Tab, Tabs } from '@mui/material'
import './style.scss'
import { useTranslation } from 'react-i18next'

interface TabItem<T> {
  label: string
  value: T
}

interface Props<T> {
  activeTab: T
  onChange: (event: React.SyntheticEvent, newStatus: T) => void
  tabs: TabItem<T>[]
}

export default function CustomTabs<T>({ activeTab, onChange, tabs }: Props<T>) {
  const { t } = useTranslation()

  return (
    <Tabs
      value={activeTab}
      onChange={onChange}
      className="tabs"
      sx={
        {
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }
      }
    >
      {
        tabs.map(tab => (
          <Tab
            key={String(tab.value)}
            label={t(tab.label)}
            value={tab.value}
            className={`tabs__item ${activeTab === tab.value ? 'tabs__item_selected' : ''}`}
          />
        ))
      }
    </Tabs>
  )
}

import { useEffect, useState } from 'react'
import './style.scss'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { CourseAdminStatus, coursesActions } from 'src/redux/courses/courseSlice'
import { ReactComponent as TrashIcon } from '../../../src/assets/icons/trash-icon.svg'
import { ReactComponent as EditIcon } from '../../../src/assets/icons/edit-icon.svg'
import { Link } from 'react-router'
import CustomTabs from '../shared/CustomTabs'

export default function CourseAuthoringPage() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const list = useAppSelector(state => state.courses.list)
  const isLoading = useAppSelector(state => state.courses.isLoading)

  const [activeTab, setActiveTab] = useState<CourseAdminStatus>('published')

  useEffect(() => {
    dispatch(coursesActions.getCourses())
  }, [])

  const handleChangeTabType = (event: React.SyntheticEvent, newStatus: CourseAdminStatus) => {
    setActiveTab(newStatus)
  }

  const filteredCourses = list.filter(course => course.status === activeTab)

  const adminTabs: { label: string; value: CourseAdminStatus }[] = [
    { label: 'admin-page.btn-published', value: 'published' },
    { label: 'admin-page.btn-draft', value: 'draft' },
    { label: 'admin-page.btn-deleted', value: 'deleted' },
  ]

  return (
    <div className="admin">
      <div className="container">
        <div className="title">{t('admin-page.summary-title')}</div>
        <CustomTabs
          activeTab={activeTab}
          onChange={handleChangeTabType}
          tabs={adminTabs}
        />
        <div className="wrap-create">
          <button className="wrap-create__btn">
            {t('admin-page.btn-create')}
            <img src="/assets/icons/redirect-icon.svg" className="wrap-create__icon" />
          </button>
        </div>

        {
          isLoading
            ? (
                <CircularProgress size={60} className="loader" />
              )
            : filteredCourses.length
              ? (
                  filteredCourses.map(course => (
                    <div key={course.courseId} className="course">
                      <Link
                        className={
                          `course__title ${
                            activeTab === 'deleted' || activeTab === 'draft' ? 'course__title_gray' : ''
                          }`
                        }
                        to=""
                      >
                        {course.courseLocales[0].title}
                      </Link>
                      <div className="course__date">
                        <span className="mr-8">
                          {activeTab === 'published' && t('admin-page.text-published')}
                          {activeTab === 'draft' && t('admin-page.text-draft')}
                          {activeTab === 'deleted' && t('admin-page.text-closed')}
                        </span>
                        {course.statusChangeDate?.toLocaleDateString()}
                      </div>
                      <button className="course__button" disabled={activeTab === 'deleted'}>
                        <EditIcon className={activeTab === 'deleted' ? 'course__icon_gray' : ''} />
                      </button>
                      <button className="course__button" disabled={activeTab === 'deleted'}>
                        <TrashIcon className={activeTab === 'deleted' ? 'course__icon_gray' : ''} />
                      </button>
                    </div>
                  ))
                )
              : (
                  <div className="text-empty">{t('admin-page.empty-plug')}</div>
                )
        }
      </div>
    </div>
  )
}

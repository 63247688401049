import { Checkbox } from '@mui/material'
import { useState } from 'react'
import CustomTabs from '../shared/CustomTabs'
import './style.scss'
import CustomPopover from '../shared/CustomPopover'
import { useTranslation } from 'react-i18next'

type Roles = {
  contentAuthorRole: boolean
  userManagerRole: boolean
  courseReviewerRole: boolean
}

type AdminRoleTabStatus = 'selectRole' | 'listUsers'

const AdminRolePage = () => {
  const { t } = useTranslation()

  const adminRoleTabs: { label: string; value: AdminRoleTabStatus }[] = [
    { label: 'management-role.select-role-tab', value: 'selectRole' },
    { label: 'management-role.list-users-tab', value: 'listUsers' },
  ]

  const [email, setEmail] = useState('')
  const [roles, setRoles] = useState<Roles>()
  const [activeTab, setActiveTab] = useState<AdminRoleTabStatus>('selectRole')
  const [UserFound] = useState<boolean | null>(null)

  const updateUserRole = (role: keyof Roles) => {
    setRoles(prevRoles => {
      if (!prevRoles) return prevRoles
      return {
        ...prevRoles,
        [role]: !prevRoles[role],
      }
    })
  }

  const switchTab = (event: React.SyntheticEvent, newStatus: AdminRoleTabStatus) => {
    setActiveTab(newStatus)
  }

  return (
    <div className="role-page">
      <CustomTabs
        tabs={adminRoleTabs}
        activeTab={activeTab}
        onChange={switchTab}
      />
      {
        activeTab === 'selectRole' && (
          <div>
            <p className="role-page__title">{t('management-role.select-role-title')}</p>

            <div>
              <p className="role-page__email-text">{t('management-role.email-hint')}</p>
              <input
                type="email"
                className="role-page__email-input"
                value={email}
                onChange={event => setEmail(event.target.value)}
                placeholder="Email"
              />
            </div>
            <button className="role-page__email-button">{t('management-role.btn-find')}</button>
            {
              UserFound !== null && (
                <div className="role-page-result">
                  {
                    UserFound
                      ? (
                          <>
                            <p className="role-page-result__label">{t('management-role.user-found')}</p>
                            <p>
                              <span className="role-page-result__label">Email:</span>
                              <span className="role-page-result__value">User</span>
                            </p>
                          </>
                        )
                      : (
                          <p>{t('management-role.user-not-found')}</p>
                        )
                  }
                  {
                    UserFound && roles && (
                      <div className="checkbox-container">
                        <p className="checkbox-container__text">{t('management-role.role-hint')}</p>
                        <div>
                          <div className="checkbox-container__item">
                            <Checkbox
                              className="checkbox"
                              checked={roles.contentAuthorRole}
                              onChange={() => updateUserRole('contentAuthorRole')}
                            />
                            <span className="checkbox-container__role">{t('management-role.content-author')}</span>
                            <CustomPopover popoverHint="The user can create projects" />
                          </div>
                          <div className="checkbox-container__item">
                            <Checkbox
                              className="checkbox"
                              checked={roles.userManagerRole}
                              onChange={() => updateUserRole('userManagerRole')}
                            />
                            <span className="checkbox-container__role">{t('management-role.user-manager')}</span>
                            <CustomPopover popoverHint="The user can give roles" />
                          </div>
                          <div className="checkbox-container__item">
                            <Checkbox
                              className="checkbox"
                              checked={roles.courseReviewerRole}
                              onChange={() => updateUserRole('courseReviewerRole')}
                            />
                            <span className="checkbox-container__role">{t('management-role.course-reviewer')}</span>
                            <CustomPopover popoverHint="The user can approve the creation of courses" />
                          </div>
                        </div>
                        <button className="checkbox-container__button">{t('management-role.btn-update-role')}</button>
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
        )
      }
      {
        activeTab === 'listUsers' && (
          <div>
            <p className="role-page__title">{t('management-role.list-users-title')}</p>
          </div>
        )
      }
    </div>
  )
}

export default AdminRolePage

import './style.scss'
import { Trans, useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { signIn } from '../../services/Auth'
import { Link, useNavigate } from 'react-router'
import { Button } from '@mui/material'
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/chevron-left.svg'

interface Window {
  google?: Google
}

export default function AuthPage() {
  const { t } = useTranslation()
  const googleSignInBtn = useRef(null)
  const navigate = useNavigate()
  const [google, setGoogle] = useState<Google>()
  const [googleIsLoading, setGoogleIsLoading] = useState(true)
  const [isAuthFailed, setIsAuthFailed] = useState(false)

  const handleGoogleResponse = async (googleRes: CredentialResponse): Promise<void> => {
    try {
      const userData = await signIn(googleRes?.credential as string)
      localStorage.setItem('token', googleRes?.credential as string)
      localStorage.setItem('userId', `${userData.data.id}`)
      navigate('/')
    } catch (e) {
      console.log(e)
      setIsAuthFailed(!isAuthFailed)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!google && (window as unknown as Window)?.google) {
        setGoogle((window as unknown as Window).google)
        setGoogleIsLoading(false)
      }
    }, 100)

    return () => clearInterval(interval)
  }, [googleIsLoading])

  useEffect(() => {
    google
    && google.accounts.id.initialize({
      client_id: process.env.REACT_APP_CLIENT_ID ?? '',
      callback: handleGoogleResponse,
      cancel_on_tap_outside: false,
      context: 'signin',
      ux_mode: 'popup',
      itp_support: true,
      use_fedcm_for_prompt: true,
    })
    google?.accounts.id.prompt()
  }, [google])

  useEffect(() => {
    google
    && !isAuthFailed
    && googleSignInBtn.current
    && google.accounts.id.renderButton(googleSignInBtn.current, {
      type: 'standard',
      theme: 'outline',
      text: 'continue_with',
      width: `${(googleSignInBtn.current as unknown as HTMLDivElement).clientWidth}`,
      logo_alignment: 'center',
    })
  }, [googleSignInBtn, isAuthFailed, google])

  return (
    <div className="auth">
      <div className="auth-container">
        {
          !isAuthFailed && (
            <img
              className="auth-corner-img"
              src="/assets/images/astronaut.svg"
              alt="astronaut"
            />
          )
        }
        <div className="auth-welcome">
          <img
            className="auth-logo"
            src="/assets/images/kaz-logo.svg"
            alt="logo"
          />
          <h2 className="auth-title">{!isAuthFailed ? t('auth-title') : t('auth-failed-title')}</h2>
          <div className="auth-info">{!isAuthFailed ? t('auth-info') : t('auth-failed-info')}</div>

          {
            !isAuthFailed
              ? (
                  <div ref={googleSignInBtn} className="auth-btn"></div>
                )
              : (
                  <Button
                    variant="outlined"
                    className="auth-back-btn"
                    onClick={() => setIsAuthFailed(!isAuthFailed)}
                  >
                    <ChevronLeftIcon className="mr-8" />
                    {t('auth-back-btn')}
                  </Button>
                )
          }
        </div>

        {
          !isAuthFailed && (
            <div className="auth-rules">
              <Trans i18nKey="auth-rules">
                <Link to=""></Link>
                <Link to=""></Link>
              </Trans>
            </div>
          )
        }
      </div>
    </div>
  )
}

import './style.scss'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { useTranslation } from 'react-i18next'
import { coursesActions, Course } from '../../../redux/courses/courseSlice'
import CourseCard from './CourseCard'

export default function CourseList() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const list = useAppSelector(state => state.courses.list)

  useEffect(() => {
    dispatch(coursesActions.getCourses())
  }, [])

  return (
    <div className="courses">
      <div className="container">
        <h3 className="courses-title">{t('courses-title')}</h3>

        <div className="courses-list">
          {
            !!list.length
            && list.map((course: Course) => (
              <CourseCard
                key={course.courseId}
                course={course}
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}
